import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';

export class LPItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
    }

    handleClose = () => this.setState({ show: false });
    handleShow = () => this.setState({ show: true });

    getOperationType = () => {
        // Precheck
        if (this.props.data.zfunction.length === 0) {
            return;
        }

        var operation = "";
        const data = this.props.data.zfunction;

        if (data.operation_type === "max") {
            operation += "Max";
        }
        else if (data.operation_type === "min") {
            operation += "Min";
        }
        else {
            operation += "Undefined";
        }
        operation += " z = ";

        for (let index = 0; index < data.xz.length; index++) {
            if (index > 0) {
                operation += " + ";
            }
            operation += data.xz[index].value + " X" + (index + 1);
        }
        return operation;
    }

    getMethod = () => {
        var method = "Método ";
        const data = this.props.data;

        if (data.method === "graphical_method") {
            method += "Gráfico"
        } else if (data.method === "simplex") {
            method += "Simplex"
        } else {
            method += "Undefined"
        }
        return method;
    }

    getSign = (e) => {
        // Precheck
        if (!e) {
            return;
        }

        if (e >= 0) {
            return "+";
        }
        else {
            return "-";
        }
    }

    getUnsignedNumber = (e) => {
        // Precheck
        if (!e) {
            return;
        }

        if (e >= 0) {
            return e;
        }
        else {
            return e.toString().replace('-', '');
        }
    }

    getRestriction = (rest) => {
        const data = this.props.data;
        if (data.number_restrictions <= 0) {
            return;
        }
        var restriction = "";
        for (let index = 0; index < rest.xr.length; index++) {
            if (index > 0) {
                restriction += " + ";
            }
            restriction += rest.xr[index] + " X" + (index + 1);
        }
        return restriction;
    }

    getEquality = (e) => {
        if (e === "=") {
            return "=";
        } else if (e === "le") {
            return "≤";
        } else if (e === "ge") {
            return "≥";
        } else {
            return "no_sign";
        }
    }

    deleteProgram = () => {
        axios.delete(`https://applp-engine.herokuapp.com/api/${this.props.data._id.$oid}`)
            .then(res => {
                console.log(res);
                window.location.reload();
            })
            .catch(err => console.error(err));


    }

    render() {
        const data = this.props.data;
        // var setID = this.props.setID;
        return (
            <div>
                <div className="row mb-2">
                    <div className="col-md">
                        <button className="btn btn-outline-info list-group-item-action" onClick={this.handleShow}>
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <h5 className="mb-1">{data.name}</h5>
                                    <small>{this.getMethod()}</small>
                                </div>

                                <p className="mb-1">{this.getOperationType()}</p>
                                <small className="mb-1">Variables: {data.number_vars}</small>
                                <br />
                                <small className="mb-1">Restricciones: {data.number_restrictions}</small>
                            </div>
                        </button>
                    </div>
                </div>

                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{data.name}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="mb-1">{this.getOperationType()}</p>
                        <p>Creado en el {this.getMethod()}</p>
                        {
                            (data.restrictions.length > 0) &&
                            data.restrictions.map(rest => (
                                <p key={"p-" + rest.json_id} >{this.getRestriction(rest)} {this.getEquality(rest.sign)} {rest.res}</p>
                            ))
                        }
                        <small className="mb-1">Variables: {data.number_vars}</small>
                        <br />
                        <small className="mb-1">Restricciones: {data.number_restrictions}</small>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="primary" onClick={() => this.props.setID(data._id.$oid)}>
                            <i className="material-icons align-middle pr-2">archive</i>Cargar programa
                        </Button>
                        <Button variant="danger" onClick={this.deleteProgram}>
                            <i className="material-icons align-middle pr-2">delete</i>Eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default LPItem;
