import React, { Component } from 'react'

export class GraphicalResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    hideSpinner = () => {
        this.setState({
            loading: false
        });
    };

    render() {
        var query = "http://127.0.0.1:5000/query?program=" + this.props.location.state.id;
        return (
            <div className="container">
                {
                    this.state.loading ? (
                        <div class="text-center">
                            <div class="spinner-border text-success m-5" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : null
                }
                <iframe title="result" src={query} frameBorder="0" height="600" onLoad={this.hideSpinner}></iframe>
                <div className="card">
                    <div className="card-body">Fin del programa.</div>
                </div>
            </div>
        )
    }
}

export default GraphicalResult
