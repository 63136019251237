import React, { Component } from 'react'
import '../style/Home.css';

export class Home extends Component {

    render() {
        return (
            <div>
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-lg text-center">
                            <h1>LP Solver</h1>
                        </div>
                    </div>
                    <hr />
                    <div className="row centered">
                        <h2>Programa de resolución de problemas de Programación Lineal</h2>
                    </div>
                    <hr />
                    <div className="row centered">
                        <h4>Modelos Determinísticos y Estocásticos</h4>
                    </div>
                    <div className="row centered">
                        <h5>Profesor José Roberto Bautista Atenógenes</h5>
                    </div>
                </div>
                <footer className="footer">
                    <div className="container container-footer text-center">
                        <small className="text-muted">© 2019 Yoltic Jassiel García Guzmán</small>
                    </div>
                </footer>
            </div>
        )
    }
}

export default Home
