import React, { Component } from 'react'
import { Alert } from 'react-bootstrap';
import '../style/Simplex.css';



export class SimplexResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            show: true,
        };
    }

    hideSpinner = () => {
        this.setState({
            loading: false
        });
    };

    setShow = (e) => {
        this.setState({
            show: e.value
        })
    }

    render() {
        var query = "https://applp-engine.herokuapp.com/engine?program=" + this.props.location.state.id;
        return (
            <div className="container">
                {
                    this.state.loading ? (
                        <div className="text-center">
                            <div className="spinner-border text-success m-5" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : null
                }
                <div className="embed-responsive embed-responsive-16by9 mt-3">
                    <iframe className="embed-responsive-item" title="result" src={query} onLoad={this.hideSpinner}></iframe>
                </div>
                {
                    this.state.loading ? null : this.state.show &&
                        <Alert className="mt-3" variant="success" onClose={() => this.setShow(false)} dismissible>
                            <Alert.Heading>
                                <i className="material-icons align-middle pr-2">check</i>Fin del programa
                            </Alert.Heading>
                            <p>Puede volver a empezar haciendo clic en el botón de <strong>Simplex</strong> en la barra superior.</p>
                        </Alert>
                }
            </div>
        )
    }
}

export default SimplexResult
