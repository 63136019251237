import React, { Component } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { generate } from 'shortid';
import axios from 'axios';
import LPListView from '../containers/LPListView';
import { Redirect } from 'react-router-dom';
import { Table, InputGroup, FormControl } from 'react-bootstrap';
import '../style/Simplex.css';

class OpenProgram extends Component {
    render() {
        return (
            <div className="card">
                <div className="card-header"><i className="material-icons align-middle pr-2 text-primary">archive</i> Cargar Programa</div>
                <div className="card-body">
                    <LPListView setID={this.props.setID} query={''}></LPListView>
                </div>
            </div>
        );
    }
}

class InputForm extends Component {

    handleFormSubmit = (values) => {
        // Create in DB
        axios.post('https://applp-engine.herokuapp.com/api/post', values)
            .then(res => {
                // On success
                console.log(res.status);
                window.location.reload();
            })
            .catch(err => console.error(err))
    }

    handleKeyPress(target) {
        if (target.charCode === 13) {
            this.handleVars();
        }
    }


    render() {
        const minVars = 1;
        const maxVars = 20;
        const minRest = 1;
        const maxRest = 10;

        const renderForm = ({ values, handleChange, handleReset, setFieldValue }) => (
            <div className="card bg-light">
                <Form className="needs-validation">
                    <div className="card-header">
                        <h3><i className="material-icons align-middle pr-2 text-primary">data_usage</i>Datos del problema</h3>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md">
                                <div className="mt-3 mb-3">
                                    <label htmlFor="name"><i className="material-icons align-middle pr-2 text-muted">dvr</i>Nombre del programa</label>
                                    <input type="text" name="name" id="name" className="form-control" value={values.name} onChange={handleChange} required />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md">
                                <FieldArray name="zfunction.xz">{({ push, remove }) => (
                                    <div className="input-group mt-3 mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text"><i className="material-icons align-middle pr-2 text-muted">format_list_numbered</i>Número de variables</span>
                                        </div>
                                        <input type="number" name="number_vars" className="form-control" min="1" max="20" step="1" required value={values.number_vars} onChange={handleChange}
                                            disabled={values.restrictions.length !== parseInt(values.number_restrictions)} />
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-secondary" type="button"
                                                onClick={() => {
                                                    if (parseInt(values.number_vars) < minVars || parseInt(values.number_vars) > maxVars) {
                                                        return;
                                                    }
                                                    if (parseInt(values.zfunction.xz.length) < parseInt(values.number_vars)) {
                                                        for (let i = parseInt(values.zfunction.xz.length); i < parseInt(values.number_vars); i++) {
                                                            push({ name: "X" + (i + 1), value: 0 });
                                                        }
                                                        // Append zeroes to restrictions
                                                        var data = [];
                                                        var currentLength = values.restrictions[0].xr.length;
                                                        var length = values.number_vars - currentLength;
                                                        // var currentIndex = currentLength;
                                                        for (var i = 0; i < length; i++) {
                                                            data.push(0);
                                                        }
                                                        for (let i = 0; i < parseInt(values.number_restrictions); i++) {
                                                            values.restrictions[i].xr = values.restrictions[i].xr.concat(data);
                                                        }
                                                    }
                                                    else {
                                                        for (let i = parseInt(values.zfunction.xz.length) - 1; i >= parseInt(values.number_vars); i--) {
                                                            remove(i);
                                                        }
                                                        // Remove zeroes from restrictions
                                                        for (let i = 0; i < parseInt(values.number_restrictions); i++) {
                                                            values.restrictions[i].xr = values.restrictions[i].xr.slice(0, parseInt(values.number_vars))
                                                        }
                                                    }
                                                }}
                                                disabled={
                                                    (values.number_vars < minVars) ||
                                                    (values.number_vars > maxVars) ||
                                                    (values.restrictions.length !== parseInt(values.number_restrictions))
                                                }
                                            >Actualizar</button>
                                        </div>
                                        {
                                            (parseInt(values.number_vars) > maxVars) &&
                                            <div className="invalid-feedback">
                                                Ingrese un número de variables de 1 a 20.
                                            </div>
                                        }
                                    </div>

                                )}</FieldArray>

                            </div>

                            <div className="col-md">
                                <FieldArray name="restrictions">{({ push, remove }) => (
                                    <div className="input-group mt-3 mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text"><i className="material-icons align-middle pr-2 text-muted">insert_link</i>Número de restricciones</span>
                                        </div>
                                        <input type="number" min="1" max="10" step="1" className="form-control" name="number_restrictions" value={values.number_restrictions} onChange={handleChange} required
                                            disabled={(parseInt(values.number_vars) !== values.zfunction.xz.length)} />
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-secondary" type="button"
                                                onClick={() => {
                                                    if (parseInt(values.number_restrictions) < minRest || parseInt(values.number_restrictions) > maxRest) {
                                                        return;
                                                    }
                                                    if (parseInt(values.restrictions.length) < parseInt(values.number_restrictions)) {
                                                        // Add xr entries
                                                        var data = [];
                                                        var length = values.number_vars;
                                                        for (var i = 0; i < length; i++) {
                                                            data.push(0);
                                                        }
                                                        for (let i = parseInt(values.restrictions.length); i < parseInt(values.number_restrictions); i++) {
                                                            push({ json_id: generate(), xr: data, sign: "=", res: "" });
                                                        }
                                                    }
                                                    else {
                                                        for (let i = parseInt(values.restrictions.length) - 1; i >= parseInt(values.number_restrictions); i--) {
                                                            remove(i);
                                                        }
                                                    }
                                                }}
                                                disabled={
                                                    values.number_restrictions < minRest ||
                                                    values.number_restrictions > maxRest ||
                                                    (parseInt(values.number_vars) !== values.zfunction.xz.length)
                                                }
                                            >Actualizar</button>
                                        </div>
                                    </div>
                                )}</FieldArray>
                            </div>

                        </div>
                        <div className="row">
                            {
                                (parseInt(values.number_vars) !== values.zfunction.xz.length || parseInt(values.number_restrictions) !== values.restrictions.length) &&
                                <div className="col-md m-3 alert alert-warning" role="alert">
                                    <strong>Aviso:</strong> Debe de actualizar todos los campos de números de variables y restricciones antes de continuar.
                                </div>
                            }
                            {
                                (parseInt(values.number_vars) < minVars || parseInt(values.number_vars) > maxVars) &&
                                <div className="col-md m-3 alert alert-danger" role="alert">
                                    <strong>Error:</strong> Solamente se admiten de 1 a 20 variables.
                                </div>
                            }
                            {
                                (parseInt(values.number_restrictions) < minRest || parseInt(values.number_restrictions) > maxRest) &&
                                <div className="col-md m-3 alert alert-danger" role="alert">
                                    <strong>Error:</strong> Solamente se admiten de 1 a 10 restricciones.
                                </div>
                            }
                        </div>

                        <div className="row mt-4">
                            <div className="col-md">
                                <h5><i className="material-icons align-middle pr-2 pl-2 text-muted">table_chart</i>Tabla de datos</h5>
                            </div>
                        </div>

                        <InputGroup className="row m-2" size="sm">
                            <Table responsive bordered striped hover size="sm">
                                <thead className="thead-dark">
                                    {/* Headers */}
                                    <FieldArray name="zfunction">{() => (
                                        <tr>
                                            <th></th>
                                            {values.zfunction.xz.map((xval, xindex) => {
                                                return (
                                                    <th className="text-center" key={"headerx" + xindex + 1} name={`zfunction.xz[${xindex}]`} >X<sub>{xindex + 1}</sub></th>
                                                )
                                            })}
                                            <th className="text-center">Signo</th>
                                            <th className="text-center">RHS</th>
                                        </tr>
                                    )}</FieldArray>
                                </thead>
                                <tbody>
                                    {/* Nombre de la variable*/}
                                    <FieldArray name="zfunction">{() => (
                                        <tr>
                                            <td className="align-middle text-center"><b>Nombre variable</b></td>
                                            {values.zfunction.xz.map((xval, xindex) => {
                                                return (
                                                    <td className="align-middle" key={xindex} >
                                                        <FormControl size="sm" type="text" name={`zfunction.xz[${xindex}].name`} value={xval.name} onChange={handleChange} required />
                                                    </td>
                                                )
                                            })}
                                            <td className="text-center"></td>
                                            <td className="text-center"></td>
                                        </tr>
                                    )}</FieldArray>
                                    {/* Max/min */}
                                    <FieldArray name="restrictions">{() => (
                                        <tr>
                                            <td className="align-middle"><select className="form-control form-control-sm" name="zfunction.operation_type" value={values.zfunction.operation_type} onChange={handleChange}>
                                                <option value="max">Maximizar</option>
                                                <option value="min">Minimizar</option>
                                            </select></td>
                                            {values.zfunction.xz.map((xval, xindex) => {
                                                return (
                                                    <td className="align-middle" key={xindex}>
                                                        <FormControl size="sm" type="number" className="form-control" name={`zfunction.xz[${xindex}].value`} value={xval.value} onChange={handleChange} required />
                                                    </td>
                                                )
                                            })}
                                            <td className="text-center"></td>
                                            <td className="text-center"></td>
                                        </tr>
                                    )}</FieldArray>
                                    {/* Restricciones */}
                                    <FieldArray name="restrictions">{() => (
                                        values.restrictions.map((rest, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="align-middle text-center"><b>Restricción {index + 1}</b></td>
                                                    {
                                                        rest.xr.map((xval, xindex) => {
                                                            return (
                                                                <td className="align-middle" key={xindex}>
                                                                    <FormControl size="sm" type="number" className="form-control" name={`restrictions[${index}].xr[${xindex}]`} value={xval} onChange={handleChange} required />
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                    <td className="align-middle text-center">
                                                        <select className="form-control form-control-sm" name={`restrictions[${index}].sign`} value={rest.sign} onChange={handleChange}>
                                                            <option value="=">=</option>
                                                            <option value="le">&le;</option>
                                                            <option value="ge">&ge;</option>
                                                        </select>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <FormControl size="sm" type="number" className="form-control" name={`restrictions[${index}].res`} value={rest.res} onChange={handleChange} required />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    )}</FieldArray>
                                </tbody>
                            </Table>
                        </InputGroup>



                        {/* JSON print */}
                        {/* <div className="row">
                            <pre>{JSON.stringify(values, null, 2)}</pre>
                        </div> */}

                        {/* Buttons */}
                        <div className="form-group row justify-content-start mt-5">
                            <div className="col-sm-1">
                                <button type="submit button" className="btn btn-primary btn-block" disabled={parseInt(values.number_vars) !== values.zfunction.xz.length || parseInt(values.number_restrictions) !== values.restrictions.length}>
                                    <span className="material-icons align-middle pr-2">save</span>Guardar
                                </button>
                            </div>
                            <div className="col-sm-1">
                                <button type="button" className="btn btn-danger" onClick={event => {
                                    event.preventDefault();
                                    handleReset();
                                    values.restrictions[0].xr = [0];
                                }}>
                                    <span className="material-icons align-middle pr-2">clear</span>Resetear
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
            </div >
        )
        return (
            <div>
                <div className="row m-3 mt-5">
                    <div className="col">
                        <div className="jumbotron">
                            <h1 className="display-4">Resolución por método Simplex</h1>
                            <p className="lead">El método simplex permite resolver problemas de Programación Lineal en forma estándar de hasta 20 variables. <br /> Se admiten hasta 10 restricciones.</p>

                        </div>
                    </div>
                </div>

                <div className="row m-3">
                    <div className="col">
                        <Formik
                            initialValues={{
                                name: "",
                                method: "simplex",
                                number_vars: "1",
                                zfunction:
                                {
                                    operation_type: "max",
                                    xz: [{
                                        name: "X1",
                                        value: 0
                                    }]
                                }
                                ,
                                number_restrictions: "1",
                                restrictions: [
                                    {
                                        json_id: generate(),
                                        xr: [0],
                                        sign: "=",
                                        res: ""
                                    }
                                ]
                            }}
                            onSubmit={(values) => { this.handleFormSubmit(values); }}
                            render={renderForm}
                        />
                    </div>
                </div>
                <div className="row m-3">
                    <div className="col-sm">
                        <OpenProgram setID={this.props.setID} ></OpenProgram>
                    </div>
                </div>
            </div>
        );
    }

}

export class Simplex extends Component {
    state = {
        inputVisible: true,
        loadID: -1
    }


    handleInputVisible = () => {
        this.setState({ inputVisible: false })
    }

    setID = (value) => {
        this.setState({ loadID: value, inputVisible: false })
    }

    render() {
        if (this.state.inputVisible === false) return <Redirect to={{ pathname: '/simplex-result', state: { id: this.state.loadID } }} />
        return (
            <div className="container-fluid">
                <InputForm setID={this.setID} handleInputVisible={this.handleInputVisible}></InputForm>
            </div>
        )
    }
}

export default Simplex
