import React, { Component } from 'react';
import axios from 'axios';
import LPItem from '../components/LPItem';
import { generate } from 'shortid';

class LPListView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: 0,
            programs: [
                {
                    id: "",
                    method: "",
                    name: "",
                    number_restrictions: "",
                    number_vars: "",
                    zfunction: [
                        {
                            operation_type: "",
                            x1: "",
                            x2: "",
                        }
                    ],
                    restrictions: [
                        {
                            json_id: "",
                            x1: "",
                            x2: "",
                            sign: "",
                            res: ""
                        }
                    ]
                },
            ]
        }
    }

    componentDidMount() {
        var query = '';
        if (this.props.query) {
            query += '/query?' + this.props.query;
        }
        axios.get(`https://applp-engine.herokuapp.com/api${query}`)
            .then(res => {
                if (res.data === null) {
                    this.setState({ error: 3 });
                    return;
                }
                this.setState({
                    programs: res.data,
                    error: 2
                });
                if (this.state.programs.length === 0) {
                    this.setState({ error: 3 });
                }
            })
            .catch((error) => {
                if (error.request) {
                    this.setState({ error: 1 })
                }
            })

    }

    render() {
        return (
            <div>
                {
                    this.state.error === 0 &&
                    <div className="d-flex justify-content-center">
                        <div className="spinner-grow text-secondary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                }
                {
                    this.state.error === 1 &&
                    <div className="col-md alert alert-danger" role="alert">
                        <strong>¡Uy!, </strong> no hay conexión con el servidor de guardado. Inténtalo de nuevo más tarde.
                    </div>
                }
                {
                    this.state.error === 2 &&
                    this.state.programs.map(item => (
                        <LPItem key={generate()} data={item} setID={this.props.setID}></LPItem>
                    ))

                }
                {
                    this.state.error === 3 &&
                    <div className="col-md alert alert-info" role="alert">
                        <strong>Info:</strong> No se encontraron programas guardados o válidos para este método.
                    </div>
                }
            </div>
        )
    }
}

export default LPListView;