import React, { Component } from 'react';
import { Switch, Route, Link } from 'react-router-dom'; // import the react-router-dom components
import './style/App.css'
import Home from './components/Home';
// import GraphicalMethod from './components/GraphicalMethod';
import GraphicalResult from './containers/GraphicalResult';
import SimplexResult from './containers/SimplexResult';
import Simplex from './components/Simplex';
import { Navbar, Nav } from 'react-bootstrap';

const Main = () => (
  <main>
    <Switch>
      <Route exact path='/' component={Home} />
      {/* <Route exact path='/graphical-method' component={GraphicalMethod} /> */}
      <Route exact path='/graphical-result' component={GraphicalResult} />
      <Route exact path='/simplex-result' component={SimplexResult} />
      <Route exact path='/simplex' component={Simplex} />
      <Route exact path='/program/:programID' />
    </Switch>
  </main>
)


export class Header extends Component {
  render() {
    return (
      <Navbar collapseOnSelect expand="md" bg="info" variant="dark">
        <Navbar.Brand as={Link} to="/">LP Solver</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            {/* <Nav.Link as={Link} to="/graphical-method">Método Gráfico</Nav.Link> */}
            <Nav.Link as={Link} to="/simplex">Simplex</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

const App = () => (
  <div>
    <Header />
    <Main />
  </div>
)

export default App;
